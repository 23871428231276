import { render, staticRenderFns } from "./reservoirMonitoringSystem.vue?vue&type=template&id=1267ffe1&scoped=true"
import script from "./reservoirMonitoringSystem.vue?vue&type=script&lang=js"
export * from "./reservoirMonitoringSystem.vue?vue&type=script&lang=js"
import style0 from "./reservoirMonitoringSystem.vue?vue&type=style&index=0&id=1267ffe1&prod&lang=less&scoped=true"
import style1 from "./reservoirMonitoringSystem.vue?vue&type=style&index=1&id=1267ffe1&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1267ffe1",
  null
  
)

export default component.exports